var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { attrs: { id: "dashboard", fluid: "" } }, [
    _c("iframe", {
      attrs: {
        src: _vm.goalsDashboardUrl,
        width: "100%",
        height: "800",
        allowtransparency: "",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }